import { NgModule, Injector  } from '@angular/core';
import { Route, Routes, RouterModule, CanActivate } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
// import { AuthGuardService } from './core/services/auth/auth-guard.service';
// import { RoleGuardService } from './core/services/auth/role-guard.service';
import { AuthService } from './core/services/auth.service';

const routes: Routes = [
  {
    path: "",
    redirectTo: '/',
    pathMatch: 'full'
  },{
    path: '**',
    redirectTo: '/'
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    HttpClientModule
  ],
  providers: [],
  exports: [RouterModule]
})

export class AppRoutingModule { }

