import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { HelperService } from './helper.service';

@Injectable()
export class AuthService implements CanActivate {
  authenticated: boolean = false;

  constructor(public helper: HelperService, public router: Router) { }

  canActivate(): boolean {
    return this.isAuthenticated();
  }
  public isAuthenticated(): boolean {
    return this.authenticated;
  }
  public setAuthenticated(authStatus: boolean) {
    this.authenticated = authStatus;
  }
  public userData() {
    return JSON.parse(localStorage.getItem('userData'));
  }

  public logout() {
    this.helper.modal({
      title: 'Atenção',
      msg: 'Deseja sair?',
      buttons: [{
        label: 'Sim',
        action: 'logout'
      }, {
        label: "Não",
        action: 'cancel'
      }]
    }).afterClosed().subscribe((data) => {
      switch (data) {
        case "logout":
          localStorage.clear();
          location.reload();
          // this.router.navigate(['/login']);
          break;
      }
    });
  }
}
