import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoadingComponent } from '../components/loading/loading.component';
import { AlertComponent } from '../components/alert/alert.component';
import { environment } from '../../../environments/environment';

declare var require: any;

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  public loader: any;
  constructor(
    public dialog: MatDialog,
  ) {
    try {
      let importedHelperService = require('../../expendables/services/helper/helper.service');
      if (importedHelperService.HelperService) {
        let expendableHelper = new importedHelperService.HelperService();
        for (let i in expendableHelper) {
          this[i] = expendableHelper[i];
        }
      }
    } catch (error) {
      console.log('expendable helper not found');
    }
  }

  showLoading(title: any = null) {
    this.loader = this.dialog.open(LoadingComponent, { data: { title } });
    return this.loader;
  }
  hideLoading() {
    this.loader.close();
  }

  modal(options: any) {
    const dialogRef = this.dialog.open(AlertComponent, { data: options });
    return dialogRef;
  }
  alert(title: string, msg: string) {
    const dialogRef = this.dialog.open(AlertComponent, { data: { title, msg } });
    return dialogRef;
  }

  imgSrc(src: any, extraParams:any = null,  placeholder: string = 'assets/images/noimage.png') {
    if (!src) return placeholder;

    var regExp = /\[.*?\]/;
    var matches = regExp.exec(src);

    if (matches && matches.length > 0) {
      src = JSON.parse(src);
    }
    if (typeof (src) == 'object') {
      if (src.length > 0) {
        src = src[0];
      } else {
        return '';
      }
    }

    if (src.indexOf('://') > 0) {
      return src;
    } else {
      return environment.mediaUrl + src;
    }
    return '';
  }
  splitArray(str: string, separator: string = ',') {
    if (!str) return [];
    return str.split(separator);
  }
  joinArray(array: [], glue: string = ',') {
    if (!array) return [];
    return array.join(glue);
  }
  isEmpty(v) {
    if (v === undefined || v == null || v === '' || v.length == 0) {
      return true;
    } else {
      return false;
    }
  }
  uniqid(size: number = 16) {
    return (new Date().getTime()).toString(size);
  }
  formatAddress(model, lineBreak = false) {
    if (!model || typeof (model) != 'object' || !model.address) return '';
    var address = model.address + (model.number ? ', ' + model.number : '') + (model.complement ? ' - ' + model.complement : '') + (lineBreak ? '<br>' : ' - ') + model.district + (lineBreak ? '<br>' : ' • ') + model.city + ' / ' + model.state;
    return address;
  }

  indexOfProperty = function (array, key, value): number {
    for (let i in array)
      if (array[i][key] == value) return parseInt(i);
    return -1;
  }

  cloneObj(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

}
