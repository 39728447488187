import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public loader: any;
  constructor(
  ) {
  }

  messageErrorHandler(error) {
    let msg: string = 'Algo de errado não está certo';
    switch (error) {
      case "invalid_login":
        msg = 'Login e/ou Senha inválidos!';
        break;
      case "user_has_subscriptions":
        msg = 'Não foi possível excluir, usuário possui assinatura ativa!';
        break;
      case "existing_user":
        msg = 'Usuário já existente';
        break;
      case "password_mismatch":
        msg = 'Senhas não conferem';
        break;
      case "mandatory_fields":
        msg = 'Campos obrigatórios';
        break;
      case "invalid_id":
        msg = 'Id inválido';
        break;
      case "username_exists":
        msg = 'Usuário já possue um cadastro';
        break;
    }
    return msg;
  }



}
