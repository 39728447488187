import { projectVars } from './environment_vars';

export const environment = {
  production: false,
  local: true,
  projectName: projectVars.name,
  baseHref: '/',
  apiUrl: 'https://api.pastoravirginiaestevao.org/v2.0/',
  mediaUrl: 'https://s3.amazonaws.com/app.curainterior/_original/'
};
