import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { LangService } from 'src/app/core/services/lang.service';
import { ICON_REGISTRY_PROVIDER } from '@angular/material';


@Injectable({
  providedIn: 'root'
})
export class HelperService {

  public loader: any;
  constructor(
  ) {
  }



  renderUpload(item) {
    let img = '';
    ['icon', 'icons', 'image', 'thumb', 'images'].forEach((el) => {
      if (item.uploads && item.uploads[el] && item.uploads[el].length > 0) {
        img = item.uploads[el][0];
      }
    });
    if (img == '') {
      return '';
    }
    let url = environment.mediaUrl + img;
    return '<img style="width:90px" src="' + url + '">';
  }

  shortText(item){
    if (typeof(item.description_br) == 'string'){
      return item.description_br.substring(0,50)+'[...]';
    }
    return '';
  }

}
