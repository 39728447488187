import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { HelperService } from './helper.service';

import { environment } from '../../../environments/environment';
import { isBoolean } from 'util';

import { AuthService } from './auth.service'

import { map } from 'rxjs/operators';
import { LangService } from './lang.service';

declare var require: any;


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public coreurl = '';
  public loader;

  constructor(
    public helper: HelperService,
    public auth: AuthService,
    private http: HttpClient,
    private ngZone: NgZone,
    public lang: LangService
  ) {
    try {
      let importedApiService = require('../../expendables/services/api/api.service');
      if (importedApiService.ApiService) {
        let expendableApi = new importedApiService.ApiService();
        for (let i in expendableApi) {
          this[i] = expendableApi[i];
        }
      }
    } catch (error) {
      console.log('expendable api not found');
    }
    if (localStorage.getItem('authenticated') === 'true') {
      this.auth.setAuthenticated(true);
    }
  }

  apiCall(method: string, endpoint: string, payload: any, loadingMessage: any = false, _options: any = {}): Promise<any> {
    const headers = new HttpHeaders;
    const options: any = {};

    let fullUrl = '';

    options.observe = 'response';
    options.reportProgress = true;
    options.headers = headers;
    if (endpoint.indexOf('://') > 0 || endpoint.indexOf('../') >= 0) {
      fullUrl = endpoint;
    } else {
      fullUrl = environment.apiUrl + endpoint;
      if (localStorage.bearerToken) {
        options.headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.bearerToken);
      }
    }
    for (let i in _options) {
      options[i] = _options[i];
    }
    // IF COMENTADO MAS FAZER SHELL PRA OFFLINE
    // if (this.helper.isConnected()) {

    let httpRequest;
    if (method == 'get' || method == 'delete') {
      // ##### Stringifica o objeto de Payload
      var querString = new URLSearchParams(payload).toString();
      if (querString != '') {
        // ##### Verifica se a URL passada já tem query string analisando a presença do caracter "?"
        let prefix = fullUrl.indexOf('?') >= 0 ? '&' : '?';
        // ##### Monta a querystring final
        fullUrl += prefix + querString;
      }
      httpRequest = this.http[method](fullUrl, options);
    } else {
      httpRequest = this.http[method](fullUrl, payload, options);

    }

    return new Promise<any>((resolve, reject) => {
      this.ngZone.runOutsideAngular(() => {
        if (loadingMessage !== false) {
          if (isBoolean(loadingMessage) === true) {
            loadingMessage = this.lang.translate('wait');
          }
          this.loader = this.helper.showLoading(loadingMessage);
        }
        let subs = httpRequest.subscribe((res: any) => {
          this.ngZone.run((a) => {
            subs.unsubscribe();
            resolve(this.responseHandler(res));
          });
        }, (error: any) => {
          this.ngZone.run((a) => {
            subs.unsubscribe();
            reject(this.responseHandler(error));
          });
        });
      });
    });
  }

  get(endpoint: string, payload: any = {}, loadingMessage: any = false, _options: any = {}) {
    return this.apiCall('get', endpoint, payload, loadingMessage, _options);
  }

  post(endpoint: string, payload: any = {}, loadingMessage: any = true, _options: any = {}) {
    return this.apiCall('post', endpoint, payload, loadingMessage, _options);
  }

  put(endpoint: string, payload: any = {}, loadingMessage: any = true, _options: any = {}) {
    return this.apiCall('put', endpoint, payload, loadingMessage, _options);
  }

  delete(endpoint: string, payload: any = {}, loadingMessage: any = true, _options: any = {}) {
    return this.apiCall('delete', endpoint, payload, loadingMessage, _options);
  }

  errorHandler(error: string, showAlert: boolean = true) {
    let title: string = 'Oops';
    let msg = this.messageErrorHandler(error);
    if (showAlert === true) {
      this.helper.alert(title, msg)
    }
  }

  messageErrorHandler(error) {
    let msg: string = 'Algo de errado não está certo';
    switch (error) {
      case "invalid_login":
        msg = 'Login e/ou Senha inválidos!';
        break;
      case "user_has_subscriptions":
        msg = 'Não foi possível excluir, usuário possui assinatura ativa!';
        break;
      case "existing_user":
        msg = 'Usuário já existente';
        break;
      case "password_mismatch":
        msg = 'Senhas não conferem';
        break;
      case "mandatory_fields":
        msg = 'Campos obrigatórios';
        break;
      case "invalid_id":
        msg = 'Id inválido';
        break;
    }
    return msg;
  }

  responseHandler(response) {
    if (this.helper.loader) {
      this.helper.loader.close();
    }
    try {
      if (response.headers.get('Token')) {
        localStorage.bearerToken = response.headers.get('Token');
      }
      if (response.headers.get('Authenticated')) {
        localStorage.setItem('authenticated', response.headers.get('Authenticated'));
        this.auth.setAuthenticated(response.headers.get('Authenticated') === 'true');
      }
      // return response;
    } catch (e) {
      // console.log('responseHandler', response);
    }
    switch (response.status) {
      case 200:
        return response.body;
        break;
      case 400:
        this.errorHandler(response.error);
        return { status: false };
        break;
      default:
        break;
    }
  }

  setAddressByCep(cep: string, model: any) {
    if (cep.length == 9) {
      let url = 'https://viacep.com.br/ws/' + cep.toString() + '/json';
      let subs = this.http.get(url).subscribe((result: any) => {
        if (!result.erro) {
          model.address = result.logradouro + ", , " + result.bairro;
          model.city = result.localidade;
          model.state = result.uf;
          subs.unsubscribe();
        }

        // this.states.states.map((state) => {
        //   if (state.cod === result.uf) model.state = result.uf;
        // })
      });
    }
  }

}
