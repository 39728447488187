import { NgModule } from '@angular/core';
import {
  MatMenuModule,
  MatRippleModule,
  MatButtonModule,
  MatBadgeModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatFormFieldModule,
  MatSelectModule,
  MatIconModule,
  MatSlideToggleModule,
  MatInputModule,
  MatCardModule,
  MatDatepickerModule,
  MatAutocompleteModule,
  MatProgressBarModule,
  MatNativeDateModule,
  MatTabsModule,
  MatTooltipModule,
  MatChipsModule,
  MatListModule,
  MatRadioModule,
  // DateAdapter
} from '@angular/material';
// import { MAT_DATE_LOCALE } from '@angular/material/core';
// import { MomentDateAdapter, MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  imports: [
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatRadioModule,
    MatDialogModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatButtonModule,
    MatBadgeModule,
    MatMenuModule,
    MatRippleModule,
    MatToolbarModule,
    MatIconModule,
    MatSlideToggleModule,
    MatCardModule,
    MatDatepickerModule,
    // MatMomentDateModule,
    // MomentDateAdapter,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatNativeDateModule,
    MatTabsModule,
    MatTooltipModule,
    MatChipsModule
  ],
  exports: [
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatRadioModule,
    MatDialogModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatButtonModule,
    MatBadgeModule,
    MatMenuModule,
    MatRippleModule,
    MatToolbarModule,
    MatIconModule,
    MatSlideToggleModule,
    MatCardModule,
    MatDatepickerModule,
    // MatMomentDateModule,
    // MomentDateAdapter,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatNativeDateModule,
    MatTabsModule,
    MatTooltipModule,
    MatChipsModule
  ],
  // providers: [
  //   { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  //   { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  // ]

})
export class MaterialModule { }