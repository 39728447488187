import { Directive, Input, Output, EventEmitter, ElementRef, Renderer2, HostListener, NgModule } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTest]'
})
export class TestDirective {
  @Input('mask') mask:string;
  @Input('mask-text') maskText:string;
  @Input('maskModel') set maskModel(item){

    this.model = item;
  }
  @Output() maskModelChange = new EventEmitter();

  public model: any;
  private isBackspace: boolean = false;

  constructor(public ngControl: NgControl, private el:ElementRef, private renderer: Renderer2) {
    console.log("FOI!!");
    el.nativeElement.style.backgroundColor = 'yellow';
  }

}


@NgModule({
  declarations: [
      TestDirective
  ],

  exports: [
      TestDirective
  ]
})
export class TestDirectiveModule{}
