import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LangService } from './../../services/lang.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  public buttons: any = [{
    label: 'Certo',
    destructive: true
  }];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public lang: LangService) {
    this.buttons[0].label = this.lang.translate('alright');
  	if(data.buttons) this.buttons = data.buttons;
  }

  ngOnInit() {
  }
  buttonAction() {
  	alert(1);
  	// this.
  }

}
