import { Injectable } from '@angular/core';
import { projectVars } from '../../../environments/environment_vars';
// import { internationalization } from '../../expendables/internationalization/internationalization';

declare var require: any;

@Injectable({
  providedIn: 'root'
})
export class LangService {
  languages: any = [{ label: 'BR', alias: 'br' }, { label: 'US', alias: 'us' }];
  fields: any = {};
  defaultAlias = 'br';
  systemLang = 'br';
  internationalization: any = {};
  coreInternationalization = {
    'br':
    {
      'typeToSearch': 'Digite para buscar',
      'image': 'Imagem',
      'file': 'Arquivo',
      'edit': 'Editar',
      'delete': 'Excluir',
      'remove': 'Remover',
      'export': 'Exportar',
      'import': 'Importar',
      'filter': 'Filtrar',
      'sort': 'Ordernar',
      'actions': 'Ações',
      'wait': 'Aguarde...',
      'success': 'Sucesso',
      'warning': 'Atenção',
      'alright': 'Certo',
      'cancel': 'Cancelar',
      'error': 'Erro',
      'saveSuccessMsg': 'Registro salvo com sucesso',
      'deleteSuccessMsg': 'Registro excluído com sucesso.',
      'deleteMsg': 'Deseja realmente apagar essa linha?',
      'processingFiles': 'Processando arquivos...',
      'errorLoadDataMsg': 'Erro ao carregar as informações',
    }
  }


  constructor() {
    // try {
    //   const int = require('../../expendables/internationalization/internationalization');
    //   this.internationalization = int.internationalization;
    // } catch (error) {
    //   console.warn('expendable internationalization not found');
    // }
    // if (projectVars.systemLang) {
    //   this.systemLang = projectVars.systemLang;
    // }
  }

  // lingua padrão do sistema
  setSystemLang(lang) {
    this.systemLang = lang;
  }

  // alias padrão de carregento nos campos multiligua
  setDefaultLang(lang) {
    this.defaultAlias = lang;
  }

  getField(field) {
    let l = this.defaultAlias;
    if (this.fields[field]) l = this.fields[field];
    return field + '_' + l;
  }

  setLanguage(field, setValue) {
    this.fields[field] = setValue
  }

  translate(factor) {
    if (!factor) return '';
    if (this.internationalization && this.internationalization[this.systemLang] && this.internationalization[this.systemLang][factor]) return this.internationalization[this.systemLang][factor];
    return this.coreInternationalization['br'][factor];
  }
}
