import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms'
import { MaterialModule } from './core/modules/material.module'
import { MAT_DATE_LOCALE, MatPaginatorIntl } from '@angular/material';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt);
// SERVICES
// import { DirectiveService } from './core/services/directives.service';
import { AuthService } from './core/services/auth.service';
// import { AuthGuardService } from './core/services/auth/auth-guard.service';
import { AppRoutingModule } from './app-routing.module';
import { JwtModule, JwtHelperService } from '@auth0/angular-jwt';

import { AppComponent } from './app.component';
import { LoadingComponent } from './core/components/loading/loading.component';
import { AlertComponent } from './core/components/alert/alert.component';
import { HotTableModule } from '@handsontable/angular';

import { MomentModule } from 'ngx-moment';
import 'moment/locale/pt-br';

import { Router } from '@angular/router';
import { TestDirectiveModule } from './core/directives/test.directive';
import { ExternalLinkComponent } from './core/components/external-link/external-link.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  entryComponents: [
    ExternalLinkComponent,
    LoadingComponent,
    AlertComponent,
    // CellRenderer,
    // EntityPage
  ],
  declarations: [
    ExternalLinkComponent,
    AppComponent,
    LoadingComponent,
    AlertComponent,
    // TestDirective,
    // MaskDirective
    // DirectiveService
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HotTableModule.forRoot(),
    MaterialModule,
    MomentModule,
    TestDirectiveModule,
    NgbModule
    // EntityPageModule
  ],
  providers: [
    AuthService, JwtHelperService,
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: LOCALE_ID, useValue: 'pt-BR' }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
