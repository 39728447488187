import { Injectable, Compiler, Injector, NgModuleFactoryLoader, SystemJsNgModuleLoader, TemplateRef } from '@angular/core';

declare var System: any


// providers: [{ provide: NgModuleFactoryLoader, useClass: SystemJsNgModuleLoader }]


@Injectable({
  providedIn: 'root'
})

export class ComponentizerService {

  constructor(
    private _compiler: Compiler,
    private injector: Injector
  ) { }


  componentize(componentPath, componentAlias, componentType, destroy: any = true) {
    // this.compRef.componentType
    return new Promise<any>((resolve, reject) => {
      console.log('../../' + componentPath);
      import('../../' + componentPath).then((importedModule) => {
        // import('../../core/pages/entity/entity.page').then((importedModule) => {
        let component = this.createComponent(importedModule, componentAlias, componentType, destroy);
        if (component) {
          resolve(component);
        } else {
          reject('Não encontrou um Component em: ' + componentAlias);
        }
      }).catch(e => { console.warn('Erro no Módulo: ' + componentAlias, { error: e }) });

    })

  }

  createComponent(module, componentAlias, componentType, destroy) {
    const moduleNameArray = [
      this.transformComponentName(componentAlias),
      this.transformComponentName(componentType),
      'Module'
    ];
    const moduleName = moduleNameArray.join('');
    const compiledPage = this._compiler.compileModuleAndAllComponentsSync(module[moduleName]);
    const ngModuleRef = compiledPage.ngModuleFactory.create(this.injector);
    const componentSelector = 'app-' + componentAlias + (componentType == 'modal' ? '-modal' : '');
    const componentFactory = this.findComponent(compiledPage.componentFactories, componentSelector);
    if (componentFactory) {
      const componentRef = componentFactory.create(this.injector, [], null, ngModuleRef);
      const resolver = this.injector['injector'].componentFactoryResolver;
      resolver._factories.set(componentRef.componentType, componentFactory);
      if (destroy) {
        componentRef.destroy();
      }
      return componentRef.componentType;
    }
    return false;
  }

  findComponent(components, componentSelector) {
    for (let i in components) {
      if (components[i].selector == componentSelector) return components[i];
    }
  }

  transformComponentName(componentName) {
    let newComponentName: any = [];

    if (componentName.indexOf('_')) {
      newComponentName = componentName.split('_');
    }
    if (componentName.indexOf('-')) {
      newComponentName = componentName.split('-');
    }
    newComponentName = newComponentName.map((item => {
      return item[0].toUpperCase() + item.slice(1);
    }));
    newComponentName = newComponentName.join("");
    return newComponentName;
  }


}
